import React from 'react'
import './listDisplay.scss'

const ListDisplay = ({ portal, title, header }) => {
    const setPortalid = (item) => {
        sessionStorage.setItem("portalid", item.portalid)
        sessionStorage.setItem("city", item.portalname)
        if (item.parentportalid === '') {
            console.log("error in material", item.parentportalid);
            item.parentportalid = 0;
        }
        sessionStorage.setItem("parentportalid", item.parentportalid)
        window.location.reload();

    }

    return (
        <div>
            <article class="information [ card ]">
                <h2 class="title">{title}</h2>
                <p class="info">{header}</p>
                {portal.map((item) => {
                    return (
                        <div>
                            <button class="button" onClick={() => setPortalid(item)}>
                                <span>{item.portalname}</span>
                            </button>
                        </div>
                    )
                })}
            </article>
        </div>
    )
}

export default ListDisplay
