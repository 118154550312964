import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Heading from './Heading';
import Card2 from './card2';
import './style.css';
import { NavLink } from 'react-router-dom';


const Spotlight = () => {

  const [data1, setData] = useState([])
  const [data2, SetImage1] = useState([])
  const [data3, SetImage2] = useState([])
  const [data4, setData1] = useState([])
  const [portal_img1, SetPortalImg1] = useState([])
  const [portal_img2, SetPortalImg2] = useState([])
  let site = sessionStorage.getItem("site")

  const [flashnews, SetFlashnews] = useState([])
  let port3 = sessionStorage.getItem("portalid")
  let parentport = sessionStorage.getItem("parentportalid")

  useEffect(() => {
    axios.all([
      axios.post('/news/headlines/Spotlight', { "port3": port3, "parentport": parentport }),
      axios.post(`/myblock/altimage/spotlight`),
      axios.post(`/techieindex/altimage/spotlight`),
      axios.post(`/news/headlines/Spotlight/techieindex`, { "port3": port3, "parentport": parentport }),
      axios.post(`/myblock/altimage/spotlight/portalid`, { "port": port3 }),
      axios.post(`/techieindex/altimage/spotlight/portalid`, { "port": port3 }),

    ])

      .then(axios.spread((data1, data2, data3, data4, data5, data6) => {
        // output of req.
        console.log('spotlight data1', data1, 'spotlight image data2', data2, 'spotlight image  data3', data3, 'spotlight image  data4', data4)

        setData(data1.data)
        SetImage1(data2.data)
        SetImage2(data3.data)
        setData1(data4.data)
        SetPortalImg1(data5.data)
        SetPortalImg2(data6.data)

      }));

  }, []);



  return (

    <>
      <div>


        <section className='vendor'>
          <div className='container'>
            <Heading />
            <div className='content'>
              {/*     {flashnews.map((data) => {
                return (
                  <Card2 key={data.id} data={data} />
                )
              })} */}

              {site === "Myblock" && data1.map((item, i) => {

                if (portal_img1[i] === undefined) {
                  portal_img1[i] = 0;
                }

                console.log("site value", site)
                return <Card2 key={i} item={item} data={data2[i]} portal_img={portal_img1[i]}/>;
              })}
              {site === "Techie-Index" && data4.map((item, i) => {
                if (portal_img2[i] === undefined) {
                  portal_img2[i] = 0;
                }
                console.log("site value", site)
                return <Card2 key={i} item={item} data={data3[i]} portal_img={portal_img2[i]} />;
              })}
            </div>
          </div>
        </section>

        <p align="center"  >
          <NavLink to="/AllSpotlights" >More</NavLink>
        </p>

      </div>
    </>
  )
}

export default Spotlight
